<template>
  <nav>
    <ul class="pagination">
      <li v-if="showFirst" @click="first()" class="page-item">
        <a class="page-link" href="#">First</a>
      </li>
      <li v-for="p in pages" :key="p" class="page-item"
          v-bind:class="{ active: isCurrentPage(p) }"
          @click="changePage(p)">
        <a class="page-link" href="#">{{ p }}</a>
      </li>
      <li v-if="showLast" @click="last()" class="page-item"><a class="page-link" href="#">Last</a>
      </li>
    </ul>
  </nav>
</template>

<style>
.pagination {
  margin-bottom: 0;
}
</style>

<script>
export default {
  props: {
    pageCount: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
    rowThreshold: {
      type: Number,
      default: 9,
    }
  },
  emits: ['pageChanged'],
  watch: {
    'pageCount': 'watchPageCount'
  },
  computed: {
    sideOffset() {
      return Math.floor(this.rowThreshold / 2);
    },
    showFirst() {
      return this.page - this.sideOffset - 1 > 0;
    },
    showLast() {
      return this.pageCount - this.page > this.sideOffset;
    },
    pages() {
      const threshold = this.rowThreshold;
      const sideOffset = this.sideOffset;

      let fromPage = 1;
      if (this.pageCount <= threshold) {
        return this.generatePages(fromPage, this.pageCount);
      }

      let rightOffset = this.pageCount - this.page;
      if (rightOffset <= 0) {
        return this.generatePages(this.page - threshold + 1, threshold);
      }

      if (rightOffset > sideOffset) {
        return this.generatePages(Math.max(this.page - sideOffset, 1), threshold);
      }

      return this.generatePages(this.page - (threshold - rightOffset) + 1, threshold);
    },
    isCurrentPage() {
      return (page) => {
        return this.page === page;
      };
    },
  },
  methods: {
    generatePages(from, length) {
      return Array.from({length}, (_, i) => from + i);
    },
    changePage(page) {
      this.$emit('pageChanged', page);
    },
    first() {
      this.changePage(1);
    },
    last() {
      this.changePage(this.pageCount);
    },
    watchPageCount(pageCount) {
      if (this.page > pageCount) {
        this.$emit('pageChanged', pageCount);
      }
    }
  }
}
</script>