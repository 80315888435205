<template>
    <div class='container-fluid'>
        <div class='box__title'>
            <span class='fs-4'>Worklogs</span>
            <button @click='showModal()' class='btn btn-primary btn-sm add-user-button'>
                Add
            </button>
            <div class='box__right last-update-widget'>
                <div class='last-update-widget__item'>
                    <button @click='pull()'
                            class='btn btn-primary last-update-widget__action-button'>
                        <i class='fas fa-arrow-down'></i> Pull from Toggl
                    </button>
                </div>
                <div class='last-update-widget__item'>
                    <button @click='push()'
                            class='btn btn-primary last-update-widget__action-button'>
                        <i class='fas fa-arrow-up'></i> Push to Jira
                    </button>
                </div>
            </div>
        </div>
        <div class='box__content'>
            <div class='worklogs__filters'>
                <div class='filter worklogs__filters-item'>
                    <button type='button'
                            v-bind:class="{ 'btn-primary': filters.withUnsynced, 'btn-light': !filters.withUnsynced }"
                            class='btn' @click='changeFilterWithUnsynced()'>Show unsynced
                    </button>
                </div>
                <div class='filter worklogs__filters-item'>
                    <button type='button'
                            v-bind:class="{ 'btn-primary': filters.withErrors, 'btn-light': !filters.withErrors }"
                            class='btn' @click='changeFilterWithErrors()'>Show errors
                    </button>
                </div>
            </div>
            <div class='worklogs__table'>
                <table class='table'>
                    <thead>
                    <tr>
                        <th scope='col'>Issue Key</th>
                        <th scope='col'>Start</th>
                        <th scope='col'>Duration</th>
                        <th scope='col'>Synced with Jira</th>
                        <th scope='col'>Jira Worklog ID</th>
                        <th scope='col'>Error</th>
                        <th scope='col'>Created At</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for='item in items' :key='item.id' :class=getRowClass(item)>
                        <th scope='row'>{{ item.issueKey }}</th>
                        <td>{{ formatDate(item.togglStart) }}</td>
                        <td>{{ formatDuration(item.duration) }}</td>
                        <td>{{ item.isSyncWithJira }}</td>
                        <td>{{ item.jiraWorklogId }}</td>
                        <td>{{ item.jiraError }}</td>
                        <td>{{ formatDate(item.createdAt) }}</td>
                        <td>
                            <button v-if='canSync(item)' @click='syncItem(item.id)'
                                    class='btn btn-light'><i
                                class='fas fa-sync-alt'></i></button>
                            <button v-if='canDelete(item)' @click='deleteItemClick(item.id)'
                                    class='btn btn-light'><i
                                class='fas fa-trash-alt'></i></button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class='worklogs__pagination'>
                <Pagination :page-count='paginationParams.pageCount'
                            :page='paginationParams.page'
                            @page-changed='changePage' />
                <div class='worklogs__pagination_limit'>
                    <span class='fs-6'>Items per page:</span>
                    <select v-model='paginationParams.limit' @change='updateLimit'
                            class='form-select worklogs__pagination_limit-select'>
                        <option v-for='value in pageLimits' :key='value' v-bind:value='value'>
                            {{ value }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class='modal fade' id='addWorklogModal' ref='addWorklogModal' tabindex='-1'
         aria-labelledby='addWorklogModalLabel'
         aria-hidden='true'>
        <div class='modal-dialog'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h5 class='modal-title' id='addWorklogModalLabel'>Add Worklog</h5>
                    <button @click='hideModal()' type='button' class='btn-close'
                            aria-label='Close'></button>
                </div>
                <div class='modal-body'>
                    <form @submit='submitWorklog'>
                        <div class='mb-3'>
                            <label class='form-label'>Project</label>
                            <select v-model='worklogItem.projectId' class='form-select' required
                                    v-on:change='worklogItemProjectChanged'>
                                <option v-for='project in projects' :key='project.jiraKey'
                                        :value='project.jiraKey'>
                                    {{ project.jiraKey }}
                                </option>
                            </select>
                        </div>
                        <div class='mb-3'>
                            <label class='form-label'>User</label>
                            <select v-model='worklogItem.userId' class='form-select' required>
                                <option v-for='item in getUsersByProjectID(worklogItem.projectId)'
                                        :key='item.userId'
                                        :value='item.userId'>
                                    {{ item.user.togglUserName }}
                                </option>
                            </select>
                        </div>
                        <div class='mb-3'>
                            <label class='form-label'>Issue key (e.g. SUP-123)</label>
                            <input v-model='worklogItem.issueKey'
                                   type='text' required
                                   class='form-control' />
                        </div>
                        <div class='mb-3'>
                            <label class='form-label'>Start from</label>
                            <Datepicker v-model='worklogItem.togglStart' required />
                        </div>
                        <div class='mb-3'>
                            <label class='form-label'>Duration (minutes)</label>
                            <input v-model='worklogItem.duration'
                                   type='number' step='1' min='1' required
                                   class='form-control' />
                        </div>
                        <button type='submit' class='btn btn-primary'>Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.box__right {
    margin-left: auto;
}

.last-update-widget {
    display: flex;
    align-items: center;
}

.last-update-widget__item {
    display: flex;
    align-items: center;
}

.last-update-widget__item + .last-update-widget__item {
    margin-left: 1rem;
}

.worklogs__filters {
    display: flex;
}

.worklogs__filters-item + .worklogs__filters-item {
    margin-left: 16px;
}

.worklogs__table {
    margin-top: 1rem;
}

.worklogs__pagination {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.worklogs__pagination_limit {
    display: flex;
    align-items: center;
}

.worklogs__pagination_limit-select {
    width: 80px;
    margin-left: 0.5rem;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import Pagination from '../../components/Pagination/Pagination';
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

export default {
    data() {
        return {
            pageLimits: [10, 25, 50, 100],
            addWorklogModal: null,
        };
    },
    components: {
        Pagination,
        Datepicker,
    },
    computed: {
        ...mapState('worklogs', [
            'items',
            'paginationParams',
            'filters',
            'isOpen',
            'worklogItem',
        ]),
        ...mapGetters('projects', [
            'getUsersByProjectID',
        ]),
        ...mapState('users', [
            'users',
        ]),
        ...mapState('projects', [
            'projects',
        ]),
    },
    watch: {
        isOpen: 'isOpenChanged',
    },
    methods: {
        ...mapActions('worklogs', [
            'loadItems',
            'changePage',
            'changeFilterWithErrors',
            'changeFilterWithUnsynced',
            'changeLimit',
            'syncItem',
            'pull',
            'push',
            'showModal',
            'hideModal',
            'saveWorklog',
            'deleteItem',
        ]),
        ...mapActions('projects', [
            'loadProjects',
            'loadProjectParticipants',
        ]),
        canSync(item) {
            return !item.isSyncWithJira && !item.blocked;
        },
        canDelete(item) {
            return this.canSync(item);
        },
        formatDate(dateString) {
            return dayjs(dateString).format('ddd MMMM D HH:mm:ss');
        },
        formatDuration(duration) {
            return dayjs.duration(duration, 'seconds').format('H:mm:ss');
        },
        getRowClass(item) {
            if (item.isSyncWithJira) {
                return 'table-success';
            }

            if (item.jiraError) {
                return 'table-danger';
            }

            return '';
        },
        updateLimit(e) {
            this.changeLimit(e.target.value);
        },
        isOpenChanged(isOpen) {
            if (!this.addWorklogModal) {
                this.addWorklogModal = new window.bootstrap.Modal(this.$refs.addWorklogModal);
                this.$refs.addWorklogModal.addEventListener('hidden.bs.modal', () => {
                    this.hideModal();
                });
            }

            if (isOpen) {
                this.addWorklogModal.show();
            } else {
                this.addWorklogModal.hide();
            }
        },
        async worklogItemProjectChanged() {
            await this.loadProjectParticipants({ projectId: this.worklogItem.projectId });
            delete this.worklogItem.userId;
        },
        submitWorklog(e) {
            e.preventDefault();
            this.saveWorklog(this.worklogItem);
        },
        deleteItemClick(itemId) {
            if (!confirm('Are you sure?')) {
                return;
            }

            this.deleteItem(itemId);
        },
    },
    created() {
        this.loadItems();
        this.loadProjects();
    },
};
</script>
